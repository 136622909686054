<template>
  <van-button type="primary" v-on:click="celebrateOnWhatsapp">
    Celebrate on Whatsapp
  </van-button>
</template>

<script>
import { isAndroid } from "mobile-device-detect";

export default {
  name: "NotifyJournalInWhatsapp",
  props: {
    step: Object,
    matchNotification: String
  },

  methods: {
    celebrateOnWhatsapp() {
      let whatsappHost = "";
      if (isAndroid) {
        whatsappHost = "whatsapp://send";
      } else {
        whatsappHost = "https://wa.me/";
      }

      const whatsappUrl =
        whatsappHost +
        `?text=${encodeURIComponent(
          this.step.notification_message + " " + this.matchNotification
        )}`;

      if (window.nsWebViewBridge) {
        // If this is inside native, then open the whatsapp url in the same tab.
        location.replace(whatsappUrl);
      } else {
        window.open(whatsappUrl, "_blank");
      }
    }
  }
};
</script>

<style scoped></style>
