<template>
  <div class="journal-notification">
    <br />
    <NotifyJournalInWhatsapp
      v-if="whatsappNotifyEnabled"
      :step="step"
      :matchNotification="this.matchNotification"
    />

    <br />
    <br />

    <van-button type="info" v-on:click="goBack">Close</van-button>
  </div>
</template>

<script>
import NotifyJournalInWhatsapp from "@/components/NotifyJournalInWhatsapp";
import { StepProvider } from "@/providers";

export default {
  name: "NotifyJournal",
  components: { NotifyJournalInWhatsapp },
  props: {
    step: Object,
    hasMatch: Boolean
  },

  data() {
    return {
      whatsappNotifyEnabled: false,
      matchNotification: null
    };
  },

  created() {
    const self = this;

    StepProvider.doesStepHasWhatsappNotification(this.step.id).then(data => {
      if (data["has-whatsapp"]) {
        self.whatsappNotifyEnabled = true;
      }
    });
  },

  mounted() {
    this.getMatchNotification();
  },

  methods: {
    goBack() {
      this.$router.push({
        name: "home-page"
      });
    },

    async getMatchNotification() {
      const self = this;
      StepProvider.getMatchNotification(this.step.id).then(response => {
        if (!response) {
          self.matchNotification = null;
        }
        self.matchNotification = response;
      });
    }
  }
};
</script>

<style scoped></style>
