<template>
  <div class="point-journal">
    <br />
    <p>{{ this.step.completion_message }}</p>
    <p>
      Now you are RANKED : <strong> {{ userRank }} </strong> of
      <strong> {{ totalUsers }}</strong>
    </p>
    <p>
      Your Points : <strong> {{ userPoints }} </strong>
    </p>
    <img v-if="!hasMatch" src="../assets/bursting.gif" />

    <NotifyMatch v-if="hasMatch" :step="step" />
  </div>
</template>

<script>
import { PointProvider, RoutineProvider } from "@/providers";
import NotifyMatch from "@/components/NotifyMatch";

export default {
  name: "PointJournal",
  props: {
    step: Object,
    hasMatch: Boolean
  },

  components: {
    NotifyMatch
  },

  data() {
    return {
      routineStepsCount: 0,
      totalUsers: 0,
      userRank: 0,
      userPoints: 0
    };
  },

  mounted() {
    this.getRoutineStepsCount();
    this.getUserRank();
  },

  methods: {
    getRoutineStepsCount() {
      const self = this;
      RoutineProvider.getRoutineStepsCount(this.step.routine.id).then(
        response => {
          self.routineStepsCount = response;
        }
      );
    },

    getUserRank() {
      const self = this;
      PointProvider.getUserRank(
        this.step.routine.habit.habit_config.group_id
      ).then(response => {
        self.totalUsers = response.data.total_users;
        self.userRank = response.data.user_rank;
        self.userPoints = response.data.user_points;
      });
    }
  }
};
</script>

<style scoped></style>
