<template>
  <div class="notify-match">
    <div v-if="haveSixerChance == true" class="match-info">
      <div v-if="pendingBallsToSixer == tinyGritSize - 1">
        <img :src="clap" class="clap-img-custom-width" /> <br />
        Well done! Just
        <div class="large-font">{{ pendingBallsToSixer }}</div>
        more balls to hit a sixer against the tiny demon
        <img :src="seeyou" class="seeyou-img-custom-width" />
      </div>
      <div v-else-if="pendingBallsToSixer == 1">
        <img :src="clap" class="clap-img-custom-width" /> <br />
        Almost there. Just
        <div class="large-font">one</div>
        more ball to smash to score a sixer against the tiny demon
        <img :src="seeyou" class="seeyou-img-custom-width" />
      </div>
      <div v-else-if="pendingBallsToSixer == 0">
        <img :src="sixer" class="sixer-img-custom-width" /> <br />
        Awesome! You hit a sixer against the tiny demon. A new over starts
        tomorrow, bring your best game to score another sixer against the tiny
        demon
      </div>
      <div v-else>
        <img :src="clap" class="clap-img-custom-width" /> <br />
        Well done. You have to smash just
        <div class="large-font">{{ pendingBallsToSixer }}</div>
        more balls to hit a sixer against the tiny demon
        <img :src="seeyou" class="seeyou-img-custom-width" />
      </div>
    </div>
    <div v-else class="match-info">
      <img :src="clap" class="clap-img-custom-width" /> <br />
      Whew! You staved off the tiny demon’s hat trick. Well done. Make sure to
      smash the ball tomorrow also!!
      <img :src="seeyou" class="seeyou-img-custom-width" />
    </div>
  </div>
</template>

<script>
import { StepProvider } from "@/providers";

export default {
  name: "NotifyMatch",
  props: {
    step: Object
  },

  data() {
    return {
      tinyGritSize: 0,
      pendingBallsToSixer: 0,
      haveSixerChance: false,
      clap: require("../assets/clap.png"),
      seeyou: require("../assets/seeyou.png"),
      sixer: require("../assets/sixer.png"),
      sixerHitMusic: require("../assets/music/hipoha_moment_toned.mp3")
    };
  },

  mounted() {
    this.getMatchStatus();
  },

  methods: {
    async getMatchStatus() {
      const self = this;
      StepProvider.getMatchStatus(this.step.id).then(response => {
        self.pendingBallsToSixer = response.highlights.pending_balls_to_sixer;
        self.tinyGritSize = response.highlights.tiny_grit_size;
        self.haveSixerChance = response.highlights.have_sixer_chance;

        if (self.haveSixerChance == true && self.pendingBallsToSixer == 0) {
          const audio = new Audio(this.sixerHitMusic);
          audio.play();
        }
      });
    }
  }
};
</script>

<style scoped>
img {
  width: 100%;
}
.clap-img-custom-width {
  width: 30% !important;
}
.seeyou-img-custom-width {
  width: 90% !important;
}
.sixer-img-custom-width {
  width: 30% !important;
}
.match-info {
  margin: 20px;
  padding: 2px;
  font-size: large;
  font-weight: 900;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-color: #f44;
  border-style: inset;
  background: #e7e6e6;
  border-width: 5px;
}
.large-font {
  font-size: xx-large;
}
</style>
