<template>
  <div class="match">
    <br />
    <div>
      <div>
        <h4 class="step-name">{{ this.step.name }}</h4>
      </div>
      <div class="content">
        <table width="100%" class="match-content">
          <tr>
            <td class="tinydemon-bg">
              <img :src="avatar" />
            </td>
            <td class="versus">
              <img src="../assets/vs.png" />
            </td>
            <td class="tinydemon-bg">
              <img src="../assets/tinydemon.png" />
            </td>
          </tr>
          <tr>
            <td class="score-grit">
              Sixers<br />
              <div class="grit-box box">
                &nbsp;&nbsp;{{ match.tiny_grit_score }}&nbsp;&nbsp;
              </div>
            </td>
            <td></td>
            <td class="score-demon">
              Hat tricks<br />
              <div class="demon-box box">
                &nbsp;&nbsp;{{ match.tiny_demon_score }}&nbsp;&nbsp;
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="current-over-status">
              <b>Current Over Status</b>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <img
                v-for="(value, index) in balls"
                :key="index"
                class="balls"
                :src="
                  value == 1
                    ? avatar
                    : require('../assets/ball' + value + '.png')
                "
              />
            </td>
          </tr>
        </table>
      </div>
      <div class="button_div">
        <span class="button_left">
          <van-button type="primary" size="small" v-on:click="goBack">
            Back
          </van-button>
        </span>
        <span class="button_right">
          <van-button
            type="primary"
            size="small"
            v-on:click="continueToJournaling"
          >
            Continue
          </van-button>
        </span>
      </div>
    </div>

    <div class="score_info">
      <hr />
      <span class="score-grit">
        <b>Sixer:</b>
        To score a sixer do your BTG all
        {{ match.match_config ? match.match_config.tiny_grit_size : 0 }}
        days in an over.
      </span>
      <br />
      <span class="score-demon">
        <b>Hat trick:</b>
        Tiny Demon scores a hat trick if you miss
        {{ match.match_config ? match.match_config.tiny_demon_size : 0 }}
        BTGs in a row in an over. Don't let it score.
      </span>
      <hr />
    </div>
  </div>
</template>

<script>
import { StepProvider } from "@/providers";

export default {
  name: "Match",
  props: {
    step: Object
  },
  data() {
    return {
      match: [],
      balls: [],
      highlights: [],
      avatar: require("../assets/me.png"),
      ballHitMusic: require("../assets/music/hit.mp3")
    };
  },

  created() {
    this.getMatchStatus();
    this.getUserMatch();
  },

  methods: {
    async getMatchStatus() {
      const self = this;
      StepProvider.getMatchStatus(this.step.id).then(response => {
        self.balls = response.balls;
        self.highlights = response.highlights;
      });
    },
    async getUserMatch() {
      const self = this;
      StepProvider.getMatch(this.step.id).then(response => {
        self.match = response;
      });
    },

    continueToJournaling() {
      this.$emit("clicked", this.match);
      const audio = new Audio(this.ballHitMusic);
      audio.play();
    },

    goBack() {
      this.$router.push({
        name: "home-page"
      });
    }
  }
};
</script>

<style scoped>
.content {
  margin: 20px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-color: #f44;
  border-style: inset;
  border-width: 5px;
  background: #e7e6e6;
}
.balls {
  border-radius: 50%;
  margin: 5px;
  height: 40px;
  width: 40px;
}
.tinydemon-bg {
  vertical-align: middle;
}
.versus {
  width: 25%;
  vertical-align: middle;
}
.score-grit {
  color: #003366;
  text-align: center;
}
.score-demon {
  color: #cc0033;
  text-align: center;
  width: 35%;
}
.box {
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-style: inset;
  border-width: 4px;
  position: relative;
  display: inline-table;
  font-size: 18px;
  padding: 0px 4px 0px 4px;
}
.grit-box {
  border-color: #003366;
}
.demon-box {
  border-color: #cc0033;
}
.match-content {
  padding-top: 10px;
  padding-bottom: 10px;
}
td {
  text-align: center;
}
td[colspan="3"] {
  text-align: center;
}
img {
  width: 100%;
}
.button_left {
  float: left;
}
.button_right {
  float: right;
}
.button_div {
  margin: 20px;
}
.current-over-status {
  padding-top: 10px;
}
.score_info {
  padding: 35px 16px 10px 16px;
}
</style>
