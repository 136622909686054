<template>
  <component :is="journalFactory" :step="step"></component>
</template>

<script>
export default {
  name: "DoJournal",
  props: {
    step: Object
  },
  methods: {
    journalFactory() {
      return import("@/components/Journal/" + this.step.type + "Journal");
    }
  }
};
</script>

<style scoped></style>
